import React, { useState, useEffect, useContext, useRef } from "react";
import "./ProductDetails.css";
import { useNavigate } from "react-router-dom";
// import { toBlob } from "html-to-image";
import SocailModal from "./SocialModal";
import Footer from "../layouts/Footer";
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles and modules
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, EffectCreative, Navigation } from "swiper";
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import { Link, useParams } from "react-router-dom";
import shareWhite from "../assets/share-white.png";
import shareBlack from "../assets/share-black.png";
import { getProductById } from "../services/product";
import back from "../images/back.jpeg";
import swal from "sweetalert";
import SDK from "../SDK";
import { ChatContext } from "../context/ChatContext";
import { AuthContext } from "../context/AuthContext";

// Add Head Tag to share properly
var link = document.createElement("meta");
link.setAttribute("property", "og:url");
link.content = document.location;

// <meta property="og:description" content="How to change the address bar color in Chrome, Firefox, Opera, Safari" />
//<meta property="og:url" content="http://webdevelopmentscripts.com/64-how-to-change-the-address-bar-color-in-chrome-firefox-opera-safari" />
//<meta property="og:image" content="http://webdevelopmentscripts.com/post-images/685b-change-browser-address-bar-color-chrome-android.jpeg" />
var link2 = document.createElement("meta");
// var link3 = document.createElement('meta');
link2.setAttribute("property", "og:image");
link2.content =
  "https://www.webdevelopmentscripts.com/post-images/685b-change-browser-address-bar-color-chrome-android.jpeg";
document.getElementsByTagName("head")[0].appendChild(link);
document.getElementsByTagName("head")[0].appendChild(link2);

const baseUrl = process.env.REACT_APP_API_URL;
export default function ProductDetails() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 801);
  const [product, setProduct] = useState([
    {
      pName: "Loading",
      pPrice: "Loading",
      pDescription: "Loading",
      pImages: ["//unsplash.it/500/500"],
    },
  ]);
  const { data, dispatch } = useContext(ChatContext);
  const { currentUser } = useContext(AuthContext);

  const handleCreateConversation = async () => {
    // dispatch({ type: "SET_PRODUCT", payload: urlParams });
    const { userJid } = SDK.getJid(product.createdBy._id); // product.pName
    console.log(userJid);
    const initiateChat = await SDK.sendTextMessage(
      userJid,
      `Hi! I'm interested in purchasing ${product.pName}`
    ).then(async () => {
      await SDK.getRecentChats().then((allchats) => {
        console.log(allchats.data[allchats.data.length - 1]);
        allchats.data[allchats.data.length - 1].productId = urlParams;
      });
    });
    console.log("chat initiation : ", initiateChat);
    if (initiateChat.message !== "Success") {
      swal({
        text: "An unknown error occured! Please try later",
        icon: "error",
      });
    }
  };
  const updateMedia = () => {
    setDesktop(window.innerWidth > 801);
  };
  const [openOptions, setOpenOptions] = useState(true);
  const openoptionsModal = () => {
    if (openOptions) {
      setOpenOptions(false);
    } else {
      setOpenOptions(true);
    }
  };

  let urlParams = useParams().id;
  useEffect(() => {
    let a = async () => {
      let b = await getProductById(urlParams);
      console.log(b.Product);
      setProduct(b.Product);
    };
    a();
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const navigate = useNavigate();

  //  const[file,setFile]=useState(null);
  //  useEffect(() =>{
  //   async function getImages(){
  //     const image = await fetch ();
  //     const blob = await image.blob();
  //     const file = new File([blob],'image/png',{type: 'image/png'});
  //     setFile(file);
  //   }
  //   getImages();
  //  },[]);

  const baseUrl = process.env.REACT_APP_API_URL;
  // const dataToPass = {
  //   title: "Items sharing",
  //   text: product.pName ? product.pName : "Loading",
  //   lineChange: "\t",
  //   url: window.location.href,
  //   // files: product.pImages ? `${baseUrl}/api/photo/get-photo/`+ product.pImages[0] : "//unsplash.it/500/500"
  //   // ? `${baseUrl}/photo/get-photo/${image}`
  //   // : "//unsplash.it/500/500",
  // };

  // function shareAcross() {
  //   if (navigator.share) {
  //     navigator
  //       .share(dataToPass)
  //       .then(() => console.log("successful share"))
  //       .catch((error) => console.log("error sharing", error));
  //   } else {
  //     console.log("No support");
  //   }
  // }

  const imageRef = useRef(null);

  const handleShare = async () => {
    // const newFile = await toBlob(imageRef.current);
    // const data = {
    //   files: [
    //     new File([newFile], "product.png", {
    //       type: newFile.type,
    //     }),
    //   ],
    //   title: "Items sharing",
    //   text: product.pName ? product.pName : "Loading",
    // };

    // try {
    //   if (!navigator.canShare(data)) {
    //     console.error("Can't share");
    //   }
    //   await navigator.share(data);
    // } catch (err) {
    //   console.error(err);
    // }
    alert("Sharing")
  };

  return (
    // Added to get Image into sharable link

    <div>
      {/* <meta property="og:title" content="Sellular MarketPlace" />
<meta property="og:type" content="website" />
<meta property="og:url" content="superfly.sellular.club" />
<meta property="og:image" itemProp="image" content={product.pImages ? `${baseUrl}/photo/get-photo/`+ product.pImages[0] : "//unsplash.it/500/500"} />
<meta property="og:image:width" content="300"/>
<meta property="og:image:height" content="300"/>
<meta property="og:description" content="A Place to Buy and sell for all your needs" /> */}
      {isDesktop ? (
        <div className="p-8">
          <div className="text-2xl leading-10">
            Product Details
            <div className="half-underline"></div>
          </div>
          <div className="flex w-full p-10">
            <Swiper
              className="mySwiper w-1/2 max-w-1/2 product-slider"
              pagination={{
                dynamicBullets: true,
              }}
              effect={"creative"}
              creativeEffect={{
                prev: {
                  shadow: true,
                  translate: [0, 0, -400],
                },
                next: {
                  translate: ["100%", 0, 0],
                },
              }}
              navigation={true}
              modules={[Pagination, EffectCreative, Navigation]}
            >
              {product.pImages
                ? product.pImages.map((image) => {
                    return (
                      <SwiperSlide className="swiper-slide product-slide">
                        <div ref={imageRef}>
                          <img
                            className="object-cover w-full"
                            src={
                              product.pImages[0]
                                ? `${baseUrl}/photo/get-photo/${image}`
                                : "//unsplash.it/500/500"
                            }
                            alt="p1"
                          />
                        </div>
                      </SwiperSlide>
                    );
                  })
                : ""}
            </Swiper>
            <div className="flex flex-col justify-start pl-4 w-1/2 max-w-1/2">
              <div className="flex flex-col py-4">
                <div className="text-2xl text-white">
                  {product.pName ? product.pName : "Loading"}
                </div>
                <div className="half-underline-gray"></div>
              </div>
              <div className="py-2">
                <div className="text-xl">Product Description</div>
              </div>
              <div className="text-sm h-28 overflow-auto">
                {product.pDescription ? product.pDescription : "Loading"}
              </div>
              <div className="divide-line"></div>
              <div className="px-4 pt-4 pb-8 flex justify-between rounded-lg">
                <div className="text-lg flex items-center">
                  ₹{" "}
                  <span className="text-white text-2xl ml-2">
                    {product.pPrice ? product.pPrice : "100"}
                  </span>
                </div>
                <div>
                  <img
                    className="h-6 w-6 cursor-pointer"
                    onClick={() => handleShare()}
                    src={shareBlack}
                    alt="share"
                  />
                </div>
              </div>
              <div>
                <button
                  className="text-white text-sm font-light py-3 w-full rounded"
                  style={{ backgroundColor: "#dc3225" }}
                >
                  {product.createdBy ? (
                    <Link
                      to={"/chat/" + product.createdBy.name}
                      style={{ textDecoration: "none" }}
                    >
                      CHAT WITH SELLER
                    </Link>
                  ) : (
                    <>No Seller Available</>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {openOptions ? (
            <div className="mobile-view-contaier">
              {/* Fix this , the back button should go to the previously navigated screen , ie if a user is coming from categories , they should go back to categories and not be navigated to the home page */}
              {/* <Link to="/"> */}
              <button onClick={() => navigate(-1)}>
                <div className="back">
                  {/* &#8592; */}
                  <img src={back} alt="share" className="p-2" />
                </div>
              </button>
              {/* </Link> */}
              <div className="swiperContainer">
                <Swiper
                  className="mySwiper"
                  pagination={{
                    dynamicBullets: true,
                  }}
                  effect={"creative"}
                  creativeEffect={{
                    prev: {
                      shadow: true,
                      translate: [0, 0, -400],
                    },
                    next: {
                      translate: ["100%", 0, 0],
                    },
                  }}
                  modules={[Pagination, EffectCreative]}
                >
                  {product.pImages
                    ? product.pImages.map((image) => {
                        return (
                          <SwiperSlide className="swiper-slide product-slide">
                            <div ref={imageRef}>
                              <img
                                className="object-cover w-full animateS"
                                src={
                                  product.pImages[0]
                                    ? `${baseUrl}/photo/get-photo/${image}`
                                    : "//unsplash.it/500/500"
                                }
                                alt="p1"
                              />
                            </div>
                          </SwiperSlide>
                        );
                      })
                    : ""}
                </Swiper>
              </div>
              <div className="ProductDetails">
                <div
                  className="pricing animateS_one"
                  style={{ backgroundColor: "#20b3aa" }}
                  onClick={console.log(product.pPrice)}
                >
                  <div
                    className="text-lg flex items-center"
                    style={{ color: "white" }}
                  >
                    <span className="text-2xl ml-2" style={{ color: "white" }}>
                      <span>₹</span>
                      {product.pPrice ? product.pPrice : "Loading"}
                    </span>
                  </div>
                  <button onClick={() => handleShare()}>
                    <div>
                      <img
                        className="h-6 w-6 cursor-pointer"
                        src={shareWhite}
                        alt="share"
                      />
                    </div>
                  </button>
                </div>
                <div
                  className="productname animateS_two"
                  style={{ background: "#127d7a" }}
                >
                  <span>{product.pName ? product.pName : "Loading"}</span>
                </div>
                <div className="product-description animateS_three">
                  <div className="text-xl pt-7">
                    <strong>Product Description</strong>
                  </div>
                  <div className="text-sm  overflow-auto pt-2 pb-4 ">
                    {product.pDescription ? product.pDescription : "Loading"}
                  </div>
                </div>
                <div className="button-Container-Div flex justify-center items-center w-full animateS_four">
                  {product.createdBy ? (
                    <div>
                      {product.createdBy._id === currentUser._id ? (
                        <>You are the seller</>
                      ) : (
                        <div onClick={handleCreateConversation}>
                          <Link
                            to="/chat/?openchat=true"
                            style={{ textDecoration: "none" }}
                            state={{ productId: urlParams }}
                          >
                            CHAT WITH SELLER
                          </Link>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>No Seller Available</>
                  )}
                </div>
              </div>
            </div>
          ) : (
            // { openOptions && <SocailModal/>}
            <SocailModal />
          )}
        </>
      )}
      <Footer />
    </div>
  );
}
