import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL
// Axios Config Instance
const instance = axios.create({
    baseURL: baseUrl,
    transformResponse: [function (data) {
        let d = JSON.parse(data);
        return d;
    }]
});
// Get All Categories
export async function getAllCategories() {
    return {
        "Categories": [
            {
                "_id": "639a0e72e6988b07fba3278f",
                "cName": "Calculators",
                "cDescription": "Calculators",
                "cStatus": "Active",
                "cImage": "639a0e72e6988b07fba3278c",
                "createdAt": "2022-12-14T17:57:06.908Z",
                "updatedAt": "2022-12-14T17:57:06.908Z",
                "__v": 0
            },
            {
                "_id": "639a0e63e6988b07fba32788",
                "cName": "Stationaries",
                "cDescription": "Stationaries",
                "cStatus": "Active",
                "cImage": "639a0e62e6988b07fba32785",
                "createdAt": "2022-12-14T17:56:51.144Z",
                "updatedAt": "2022-12-14T17:56:51.144Z",
                "__v": 0
            },
            {
                "_id": "639a0e31e6988b07fba32770",
                "cName": "Books",
                "cDescription": "Books",
                "cStatus": "Active",
                "cImage": "639a0e30e6988b07fba3276c",
                "createdAt": "2022-12-14T17:56:01.113Z",
                "updatedAt": "2022-12-14T17:56:01.113Z",
                "__v": 0
            },
            {
                "_id": "639a0e1fe6988b07fba32767",
                "cName": "Electronics",
                "cDescription": "Electronics",
                "cStatus": "Active",
                "cImage": "639a0e1ee6988b07fba32764",
                "createdAt": "2022-12-14T17:55:43.522Z",
                "updatedAt": "2022-12-14T17:55:43.522Z",
                "__v": 0
            },
            {
                "_id": "639a0e09e6988b07fba32752",
                "cName": "Furniture",
                "cDescription": "Furniture",
                "cStatus": "Active",
                "cImage": "639a0e08e6988b07fba3274f",
                "createdAt": "2022-12-14T17:55:21.126Z",
                "updatedAt": "2022-12-14T17:55:21.126Z",
                "__v": 0
            },
            {
                "_id": "639a0df1e6988b07fba32744",
                "cName": "Home Essentials",
                "cDescription": "Home Essentials",
                "cStatus": "Active",
                "cImage": "639a0df1e6988b07fba32741",
                "createdAt": "2022-12-14T17:54:57.583Z",
                "updatedAt": "2022-12-14T17:54:57.583Z",
                "__v": 0
            },
            {
                "_id": "639a0dcfe6988b07fba32733",
                "cName": "Other Stuff",
                "cDescription": "Miscellaneous",
                "cStatus": "Active",
                "cImage": "639a0dcee6988b07fba3272c",
                "createdAt": "2022-12-14T17:54:23.563Z",
                "updatedAt": "2022-12-14T17:54:23.563Z",
                "__v": 0
            }
        ]
    }
    // const result = await instance.get("/category/all-category");
    // if (result.data) {
    //     return result.data
    // } else {
    //     console.log(result)
    // }
}
