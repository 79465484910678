import React, { useContext, useEffect, useState } from 'react';
import "./loginSignup.css";
import { logIn, signUp } from '../../services/auth';
import swal from 'sweetalert';
import CollegeDetails from "./waitlist/CollegeDetails";
import Logo from '../../assets/logo.png'
import VerificationModal from '../../pages/Account/VerificationModal';
import SelectFields from "./waitlist/SelectFields";
import { Link, NavLink } from 'react-router-dom';
import { CollegeContext } from '../../context/CollegeContext';
import hide from '../../assets/eye.png'
import Openeye from '../../assets/eye-ball.png'
import { Navigate } from 'react-router-dom';
const LoginSignup = () => {
    const [show, setShow] = useState(true); // shows the login by default
    const [showCollegeDetails, setShowCollegeDetails] = useState(false);
    const [data, setData] = useState({
        name: "",
        email: "",
        dob: "",
        phoneNumber: 0,
        password: "",
        cPassword: ""
    })
    useEffect(() => {
        if(data.email !== "")
        window.sessionStorage.setItem("data",JSON.stringify(data));
        console.log(data)
    },[data])
    const setDataFromSessionStorage = ()=> {
        if(data.email === ""){
            if(JSON.parse(window.sessionStorage.getItem("data"))){
                setData(JSON.parse(window.sessionStorage.getItem("data")))
            }
        }
    }
    setDataFromSessionStorage()
    const {currentCollege} = useContext(CollegeContext);
    const login = async () => {
        const loginResponse = await logIn(data.email, data.password);
        if (loginResponse.error) {
            await swal({ text: loginResponse.error, icon: "error" });
        } else if (loginResponse.token) {
            await swal({ text: "Successfully logged in" }).then(()=>{

                if(currentCollege.inWaitlist === "true") {
                    window.location.href = "/waitlist"
                } else {
                    window.location.href = "/";
                }
            });
        }
    }
    // sign up function is in CollegeDetails, because we are also taking other details
    const toggleRegister = () => {
        setShow((prev) => !prev);
        setData({
            name: "",
            email: "",
            dob: "",
            phoneNumber: 0,
            password: "",
            cPassword: ""
        })
    }

    const checkDataAndShowCollegeDetails = async () => {
        if (data.name === "" || data.email === "" || data.dob === "" || data.phoneNumber === 0 || data.password === "" || data.cPassword === "") {
            await swal({ text: "Please fill out all the fields!" })
        } else if (data.password !== data.cPassword) {
            await swal({ text: "Passwords do not match!" })
        }
        else {
            setShowCollegeDetails(true);
        }
    }
    const backBtn =()=>{
        setShowCollegeDetails(false);
    }
    //  OTP less
    // useEffect(() => {
    //     // Define the 'otpless' function
    //     window.otpless = (otplessUser) => {
    //      // Retrieve the user's details after successful login
    //      const waName = otplessUser.waName;
    //      const waNumber = otplessUser.waNumber;

    //      // Handle the signup/signin process
    //      // ...
    //     };
    //    }, []);
    // Show Password
    const[passShow,setPassShow]=useState(hide);
    const showPassword=()=>{
        let a=document.getElementById("ConfPassowrd");
        a.type==='password'?a.type='text':a.type='password';
    }
    return (
        <>
            {showCollegeDetails &&
                <div style={{ overflow: 'hidden',position:'absolute',top:'0',bottom:'0',background:'#fff',zIndex:'100' }}>
                    <div className="backBtnNav">
                        <span onClick={backBtn}><i className="fa-solid fa-circle-left"></i></span>
                    </div>
                    <CollegeDetails data={data} backBtn={setShowCollegeDetails} />
                </div>  
            }
             <div className='registerpage'>
                    <Link className='registerpage-Navbar' to="/register">
                        <span><i className="fa-solid fa-circle-left"></i></span>
                    </Link>
                    <h1>Welcome to Sellular</h1>
                    <div className='registerform'>

                        {show ?
                            <>
                                <div className='registerdata'>
                                    <div className='registerdata-Image'>
                                        <div className='registerdata-Image-Logo'>
                                            <img src={Logo} alt="" />
                                        </div>
                                        <div className='Background-Circle'>
                                            <div className="circle1"></div>
                                            <div className="circle2"></div>
                                            <div className="circle3"></div>
                                        </div>
                                    </div>
                                    {/* <h2>Login</h2> */}
                                    <div className='registerdata-loginBox'>
                                        <div className='registerdata-InputContainer'>
                                            <input type='email' className='registerinput' onChange={(e) => setData({ ...data, email: e.target.value })} autoComplete="off" required="required" />
                                            <label className='user-Lable'>Email</label>
                                        </div>
                                        <div className='registerdata-InputContainer'>
                                            <input type='password' className='registerinput' onChange={(e) => setData({ ...data, password: e.target.value })} autoComplete="off" required="required" />
                                            <label className='password-Lable'>Password</label>
                                        </div>
                                        <a className='forgetPassword' href='https://api.sellular.club/forgot-password'>Oops! Did you forget your password?</a>
                                        <span className='registerSignUp' onClick={toggleRegister}>Don't have an account? </span>
                                        <NavLink to={'/products'}>
                                        <button type='submit' className='registersubmit' 
                                        // onClick={login}
                                        >
                                            Login</button>
                                        </NavLink>
                                    </div>
                                </div>
                            </> :
                            <>
                                {/* <h2>Signup</h2> */}
                                <div className='signUp-data'>
                                    <div className='signUp-input-container'>
                                        <input type='text' className='signUp-input' autoComplete="off" required="required" onChange={(e) => setData({ ...data, name: e.target.value })} value={data.name ? data.name : "" }/>
                                        <label className='signUp-Lable'>Name</label>
                                    </div>
                                    <div className='signUp-input-container'>
                                        <input type='email' className='signUp-input' autoComplete="off" required="required" onChange={(e) => setData({ ...data, email: e.target.value })} value={data.email ? data.email : "" }/>
                                        <label className='signUp-Lable'>Email</label>
                                    </div>
                                    <div className='signUp-input-container'>
                                        <input type='date' className='signUp-input' autoComplete="off" required="required" onChange={(e) => setData({ ...data, dob: e.target.value })} value={data.dob ? data.dob : "" }/>
                                        <label className='signUp-Lable'>DOB</label>
                                    </div>
                                    <div className='signUp-input-container'>
                                        <input type='number' className='signUp-input' autoComplete="off" required="required" onChange={(e) => setData({ ...data, phoneNumber: e.target.value })} value={data.phoneNumber ? data.phoneNumber : "" }/>
                                        <label className='signUp-Lable'>Phone</label>
                                    </div>
                                    <div className='signUp-input-container'>
                                        <input type='password' className='signUp-input' autoComplete="off" required="required" onChange={(e) => setData({ ...data, password: e.target.value })} value={data.password ? data.password : "" }/>
                                        <label className='signUp-Lable'>Password</label>
                                        {/* <label htmlFor="" className='showPassword' onClick={()=> document.getElementById("ConfPassowrd").type="text"}></label> */}
                                    </div>
                                    <div className='signUp-input-container'>
                                        <input type='text' className='signUp-input' autoComplete="off" required="required" id='ConfPassowrd' onChange={(e) => setData({ ...data, cPassword: e.target.value })} value={data.cPassword ? data.cPassword : "" }/>
                                        <label className='signUp-Lable'>Confirm Password</label>
                                        {/* <label htmlFor="" className='showPassword' onClick={showPassword}></label> */}
                                    </div>
                                    <span onClick={toggleRegister}>Already have an account? Log in instead</span>
                                    <button type='submit' className='signUp-submit' onClick={checkDataAndShowCollegeDetails}>Signup</button>
                                </div>
                            </>
                        }
                    </div>
                </div>
        </>
    )
}

export default LoginSignup