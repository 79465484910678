import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL
// Upload Progress 
const config = {
    //@ts-ignore
    onUploadProgress: progressEvent => {
      const { loaded, total } = progressEvent;
  let percent = Math.floor((loaded * 100) / total);
  console.log(`${loaded} bytes of ${total} bytes. ${percent}%`);
  if (percent <= 100) {
    console.log(`${loaded} bytes of ${total} bytes. ${percent}%`);
  }
    }
}

// Bearer Token
const BearerToken = () =>
    localStorage.getItem("jwt")
        ? JSON.parse(localStorage.getItem("jwt"))
        : false;
// Axios Config Instance
const instance = axios.create({
    baseURL: baseUrl,
    // timeout: 1000,
    transformResponse: [function (data) {
        let d = JSON.parse(data);
        return d;
    }]
});
// instance.defaults.headers.common['Authorization'] = BearerToken();
// axios.defaults.headers.common['Authorization'] = BearerToken();
// Simple return function
function returnFunction(result) {
    if (result.data) {
        return result.data
    } else {
        console.log(result)
    }
}
// CRUD Products
export const createProduct = async ({
    pName,
    pDescription,
    pImage,
    pCategory,
    pPrice,
    pMrp,
    personId,
    pCollegeId,
    creatorName,
}) => {
    console.log("create product")
    /* Most important part for uploading multiple image  */
    let formData = new FormData();
    console.log(pName)
    console.log(pDescription)
    console.log(pCategory)
    console.log(pPrice)
    console.log(pMrp)
    console.log(personId)
    console.log(pCollegeId)
    console.log(creatorName)
    for (const file of pImage) {
        formData.append("pImage", file);
    }
    /* Most important part for uploading multiple image  */
    formData.append("pName", pName);
    formData.append("pDescription", pDescription);
    formData.append("pCategory", pCategory);
    formData.append("pPrice", pPrice);
    formData.append("pMrp", pMrp);
    formData.append("personId", personId);
    formData.append("pCollegeId",pCollegeId);
    formData.append("creatorName",creatorName);
    try {
        let res = await instance.post(
            '/product/add-product',
            formData,{
                headers: {
                    Authorization : "Bearer "+BearerToken()
                },config
            }
        );
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
// Get All Products

export async function getAllProducts(collegeId,page,limit) {
    const result = await instance.get(`/product/all-product/${collegeId}/?page=${page}&limit=${limit}`);
    return returnFunction(result);
}
// Get Product By Id
export async function getProductById(productId) {
    const result = await instance.post("/product/single-product", { pId: productId });
    return returnFunction(result);
}
// Create a New Product
// Update Existing Product by Id
export async function editProduct(productId, name, description, imageIds, categoryId, price, userId) {
    const result = await instance.post("/product/edit-product", { productId, name, description, imageIds, categoryId, price, userId });
    return returnFunction(result);
}
// Delete Product By Id
export async function deleteProductById(productId) {
    const result = await instance.post("/product/delete-product", { pId: productId });
    return returnFunction(result);
}
// ---------------------------------
// Get Recommneded Products
export async function getRecommendedProducts() {
    const result = await instance.get("/recommended/get-recommended");
    return returnFunction(result);
}
// Get Products By CategoryName
export async function getProductsByCategoryName(categoryName,collegeId,page,limit) {
    return [
        {
            "pImages": [
                "https://sellular-storage.s3.ap-south-1.amazonaws.com/products%2FbuySell1697820050927_661235728.jpg"
            ],
            "Features": [],
            "views": 1,
            "_id": "6532ad9a3b29073c2480e274",
            "pName": "ada",
            "pDescription": "ad",
            "pPrice": 600,
            "pCategory": "639a0e63e6988b07fba32788",
            "pCategoryName": "Stationaries",
            "pMrp": 700,
            "pCollegeId": "63e681516b9f21165a0823bf",
            "createdBy": "64ddeca5213fed2a947b5a84",
            "creatorName": "Vedant",
            "condition": "Bad",
            "quantity": 2,
            "age": "4 year",
            "originalPackagingIncluded": false,
            "pRatingsReviews": [],
            "createdAt": "2023-10-20T16:40:58.832Z",
            "updatedAt": "2023-10-25T11:01:15.811Z",
            "__v": 0
        },
        {
            "pImages": [
                "https://sellular-storage.s3.ap-south-1.amazonaws.com/products/buySell1697640082092_660330893.jpg"
            ],
            "Features": [],
            "views": 0,
            "_id": "652fee927072d396df2ad6f4",
            "pName": "dsfdsfds",
            "pDescription": "sdfdsfsdf",
            "pPrice": 122,
            "pCategory": "639a0e63e6988b07fba32788",
            "pCategoryName": "Stationaries",
            "pMrp": 565,
            "pCollegeId": "63e681516b9f21165a0823bf",
            "createdBy": "64e1bf7a8716f72d9f36f416",
            "creatorName": "null",
            "condition": "Good",
            "quantity": 5,
            "age": "9 year",
            "originalPackagingIncluded": false,
            "pRatingsReviews": [],
            "createdAt": "2023-10-18T14:41:22.348Z",
            "updatedAt": "2023-10-18T14:41:22.348Z",
            "__v": 0
        },
        {
            "pImages": [
                "//unsplash.it/500/500"
            ],
            "Features": [],
            "views": 1,
            "_id": "652fec2e24f6fb89d6ab6db9",
            "pName": "dfsdfdsfsdfdssd",
            "pDescription": "sdfdsfds b sd  sdf sfd",
            "pPrice": 23,
            "pCategory": "639a0e63e6988b07fba32788",
            "pCategoryName": "Stationaries",
            "pMrp": 4543,
            "pCollegeId": "63e681516b9f21165a0823bf",
            "createdBy": "64e1bf7a8716f72d9f36f416",
            "creatorName": "null",
            "condition": "Bad",
            "quantity": 4,
            "age": "7 year",
            "originalPackagingIncluded": false,
            "pRatingsReviews": [],
            "createdAt": "2023-10-18T14:31:10.060Z",
            "updatedAt": "2023-10-20T12:11:58.361Z",
            "__v": 0
        },
        {
            "pImages": [
                "//unsplash.it/500/600"
            ],
            "Features": [],
            "views": 1,
            "_id": "652fe10699245a6500e19576",
            "pName": "ad",
            "pDescription": "ada",
            "pPrice": 1000,
            "pCategory": "639a0e63e6988b07fba32788",
            "pCategoryName": "Stationaries",
            "pMrp": 1500,
            "pCollegeId": "63e681516b9f21165a0823bf",
            "createdBy": "64ddeca5213fed2a947b5a84",
            "creatorName": "Vedant",
            "condition": "Ok",
            "quantity": 1,
            "age": "3 year",
            "originalPackagingIncluded": false,
            "pRatingsReviews": [],
            "createdAt": "2023-10-18T13:43:34.334Z",
            "updatedAt": "2023-10-18T16:45:10.086Z",
            "__v": 0
        },
        {
            "pImages": [
                "//unsplash.it/500/500",
                "//unsplash.it/500/800",
                "//unsplash.it/500/700",
                "//unsplash.it/500/600",
            ],
            "Features": [],
            "views": 1,
            "_id": "652fdb9f99245a6500e19561",
            "pName": "adaa",
            "pDescription": "dada",
            "pPrice": 6000,
            "pCategory": "639a0e63e6988b07fba32788",
            "pCategoryName": "Stationaries",
            "pMrp": 6001,
            "pCollegeId": "63e681516b9f21165a0823bf",
            "createdBy": "64ddeca5213fed2a947b5a84",
            "creatorName": "Vedant",
            "condition": "Ok",
            "quantity": 1,
            "age": "4 year",
            "originalPackagingIncluded": false,
            "pRatingsReviews": [],
            "createdAt": "2023-10-18T13:20:31.574Z",
            "updatedAt": "2023-10-31T17:01:42.674Z",
            "__v": 0
        }
    ]
    const result = await instance.get(`/category/${categoryName}&${collegeId}/?page=${page}&limit=${limit}`);
    return returnFunction(result);
}

export const productByUser = async () => {
    let uId = JSON.parse(localStorage.getItem("jwt"));
    console.log(uId)
    try {
        let res = await instance.post(`product/product-by-user`, { jwtT: uId });
        return res.data;
    } catch (error) {
        console.log(error);
    }
};