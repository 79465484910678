// import ReactDOM from "react-dom/client";
import { useState, useEffect, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/Home/Home";
// import HomePage from "./pages/Home/mobileHome";
// import Chat from "./pages/Chat/Chat";
import Products from "./pages/Products/Products";
import Events from "./pages/Events/Events";
import EventsPage from "./pages/Events/eventsPage";
import Account from "./pages/Account/Account";
// import MyProducts from "./pages/Account/MyProducts/MyProducts"
import ProductDetails from "./components/ProductDetails";
import MobileCategoryPage from "./pages/MobileCategoryView/MobileCategoryPage.js";
import DesktopCategoryPage from "./pages/DesktopCategoryView/DesktopCategoryPage";
import ChatPage from "./pages/Chat/ChatPage";
// import ChatPage from "./pages/Chat/MirrorFlyChat/ChatPage";
import ProtectedRoute from "./components/ProtectedRoute";
// import Footer from "./layouts/Footer";
import GetStarted from "./layouts/GetStarted/GetStarted";
import LoginSignup from "./layouts/LoginSignup/LoginSignup.jsx";
import WaLogin from "./layouts/LoginSignup/WaLogin";
import WaitList from "./layouts/LoginSignup/waitlist/WaitList";
import SDK from "./SDK";
import { AuthContext } from "./context/AuthContext";
import { ChatContext } from "./context/ChatContext";
import WaitListRoute from "./components/WaitListRoute";
import Error404 from './pages/ErrorPage/Error404'

export default function App() {
  const { currentUser } = useContext(AuthContext);
  // Mirror Fly
  function connectionListener(response) {
    if (response.status === "CONNECTED") {
      console.log("Connection Established");
    } else if (response.status === "DISCONNECTED") {
      console.log("Disconnected");
    }
  }
  function messageListener(response) {
    console.log("Message Listener", response);
  }
  // const {data,dispatch} = useContext(ChatContext); 
  // useEffect(() => {
  //   if(window.location.pathname !== "/chat") {
  //     dispatch({type:"BACK_TO_SIDEBAR"});
  //   }
  // }, [window.location.pathname])
  
  useEffect(() => {
    const initSDK = async () => {
      const initializeObj = {
        apiBaseUrl: `https://api-preprod-sandbox.mirrorfly.com/api/v1`,
        licenseKey: `UqIKkayqII4HAIhPfQSdq3YhXpxrN5`,
        isTrialLicenseKey: true,
        callbackListeners: { connectionListener, messageListener },
      };
      let initSDKResponse = await SDK.initializeSDK(initializeObj);
      console.log("sdk initialization : ", initSDKResponse);
    }
    let username = "";
    let password = "";
    const registerUser = async () => {
      let regUser = await SDK.register(currentUser._id);
      console.log("user registration : ", regUser);
      username = regUser.data.username;
      password = regUser.data.password;
      if (regUser.message === "Success") {
        const connectUser = async () => {
          let conUser = await SDK.connect(username, password);
          console.log("user connection : ", conUser);
        }
        connectUser();
      }
    }
    initSDK();
    registerUser();
  })

  const [loading, setLoading] = useState(true);
  const spinner = document.getElementById("spinner");
  const [isDesktop, setDesktop] = useState(window.innerWidth > 801);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 801);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 2000);
  }
  return (
    !loading && (
      <BrowserRouter>

        <Routes>
          <Route path="/walogin/redirect/:token" element={<HomePage />}></Route>
          <Route path="/getstarted" element={<GetStarted />}></Route>
          <Route path="/register" element={<WaLogin />}></Route>
          <Route path="/loginSignUp" element={<LoginSignup />}></Route>
          <Route path="/" element={
            // <ProtectedRoute>
            //   <WaitListRoute>
                <HomePage />
            //   </WaitListRoute>
            // </ProtectedRoute>
          }></Route>
          <Route path="/waitlist" element={<WaitList />}></Route>
          <Route
            path="/chat"
            element={
              // <ProtectedRoute>
                <ChatPage />
              // </ProtectedRoute>
            }
          ></Route>
          <Route path="/products" element={<Products />}></Route>
          <Route path="/events" element={<Events />}></Route>
          <Route path="/events/category" element={<EventsPage />}></Route>
          <Route
            path="/account"
            element={
              <ProtectedRoute>
                <WaitListRoute>
                  <Account />
                </WaitListRoute>
              </ProtectedRoute>
            }
          ></Route>
          {/* <Route path="/myproducts" element={<MyProducts />}></Route> */}
          {/* Only for testing. Will be removed later */}
          <Route path="/product/:id" element={<ProductDetails />}></Route>
          <Route path="*" element={<Error404/>}></Route>
          {isDesktop ? (
            <Route
              path="/category/:name"
              element={<DesktopCategoryPage />}
            ></Route>
          ) : (
            <Route
              path="/category/:name"
              element={<MobileCategoryPage />}
            ></Route>
          )}
        </Routes>


      </BrowserRouter>
    )
  );
}

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(<App />);
