//Mobile Navbar Visible only when width is less than 801px
import React, { useContext } from "react";
import logo from "../images/logo.png";
import {Link } from 'react-router-dom';
import "./mobileNavbarStyle.css";
import { useState } from "react";
// import Login from "./LoginSignup/Login";
// import Signup from "./LoginSignup/Signup";
import { isAuthenticated } from "../services/auth";
import { AuthContext } from "../context/AuthContext";
import { logout } from "../services/auth";
import { useEffect } from "react";
import power from "../images/power-off.png";
import Account from "../images/My account.png";
import wishlist from "../images/My wishlist.png";
import HelpSupport from '../assets/help.png'
import Settings from "../images/settings.png";
import LoginSignUp from "../assets/login.png";
import { Dropdown } from "bootstrap";
import { CollegeContext } from '../context/CollegeContext';

export default function Navbar() {
  const auth = isAuthenticated();
  const [isLogin, setIsLogin] = useState(false);
  const loginModal = () => {
    setIsLogin(true);
    setLogin(true);
  };
  // const [currentUser,setCurrentUser] = useState(null)
  const [login, setLogin] = useState(true);
  const [signup, setSignup] = useState(false);
  const loginHandler = () => {
    setLogin(true);
    setSignup(false);
  };
  const signupHandler = () => {
    setLogin(false);
    setSignup(true);
  };
  const closeModal = (event) => {
    setIsLogin(false);
    setSignup(false);
  };
  const {currentCollege} = useContext(CollegeContext);

  const [dropdown,setDropDown]=useState(false);
  const openDropDown=()=>{
    if(currentCollege.inWaitlist==='true'){
      setDropDown(false);
    }
    else{
      dropdown ? setDropDown(false):setDropDown(true);
    }
  }
  const { currentUser } = useContext(AuthContext);
  console.log(currentUser);
  return (
    <>
      <nav className="navbar navbarDesignS px-2 items-center justify-between">
        <div className="container-fluid align-items-center">
            <Link to="/"><img className="logoS " src={logo} alt="/" /></Link>
            {/* <div><h6>Collages</h6></div> */}
        <button
            className="displayPictureS ms-auto"
            onClick={openDropDown}
          >
            {auth?(<div className="w-28"><h4 className="userDisplayNameS truncate text-sm py-2 px-2" style={{marginBottom: 0}}>{currentUser?currentUser.name : "Loading" }</h4></div>):(<h4>User</h4>)}
          </button>
      </div>
      </nav>
      {dropdown &&
        <div className="dropDownMenu">
        {auth ?
        <ul className="listHolderMobile ms-auto">
          <li>
            <div className="img-name-container ">
              <div className="icon-Container">
                <img src={Account} alt="/" />
              </div>
              <Link to="/account" className="linker-tag" >
                <div className="listed-item" >
                  Account
                </div>
              </Link>
            </div>
          </li>
          <li>
            <div className="border-line"></div>
              <div className="img-name-container" id="logout-section">
                <div className="icon-Container">
                  <img src={HelpSupport} alt="/" />
                </div>
                <div className="listed-item" >
                 <a href="mailto:hello.sellular@gmail.com"> Help and Support</a>
                </div>
              </div>
          </li>
        </ul>:
        <ul className="listHolderMobile ms-auto"> 
       <li>
            <div className="img-name-container" id="logout-section mt-1">
              <div className="icon-Container">
                <img src={LoginSignUp} alt="/" />
              </div>
              <Link className="listed-item" to='/register' >Login / SignUp</Link>
            </div>
        </li>
       <li>
          <div className="border-line"></div>
            <div className="img-name-container" id="logout-section">
              <div className="icon-Container">
                <img src={HelpSupport} alt="/" />
              </div>
              <div className="listed-item" >
                 <a href="mailto:hello.sellular@gmail.com"> Help and Support</a>
                </div>
            </div>
        </li>
      </ul>
      }
        </div>
      }  
      {/* Login SignUp Modal */}
      {isLogin ? (
        <div className="collageModalMobile ">
          <div className="loginPageMobile rounded">
            <div className="closeMark ms-auto me-3" onClick={closeModal}>
              <i className="fa-solid fa-xmark"></i>
            </div>{" "}
            {/* <div className="body-container">{login && <Login />}</div> */}
            <div className="link-container">
            <a href="https://api.sellular.club/forgot-password" style={{textDecoration:"none",color:"#ccc"}}>
                    <p className="bottom-text mt-2">Forgot Password?</p>
                    </a>
              <p onClick={signupHandler} className="bottom-text mt-2" style={{color:"#ccc"}}>
                Don't have an account?{" "}
                <span className="logInSignUpButton">SignUp</span>
              </p>
            </div>
            {/* <button className="border w-32 p-1 rounded-lg my-2" onClick={loginHandler}>login</button>
            <button className="border w-32 p-1 rounded-lg" onClick={signupHandler}>Sign Up</button>  */}
          </div>
        </div>
      ) : null}
      {signup ? (
        <div className="collageModalMobile ">
          <div className="loginPageMobile rounded ">
            <div className="closeMark ms-auto me-3" onClick={closeModal}>
              <i className="fa-solid fa-xmark"></i>
            </div>
            {/* {signup && <Signup />} */}
            <div className="link-container">
              <p className="bottom-text mt-2" style={{color:"#ccc"}}>
                Have an account?{" "}
                <span onClick={loginHandler} className="logInSignUpButton">
                  Login
                </span>
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}